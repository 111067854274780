import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const EconomicImpactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reportImg: file(relativePath: { eq: "economic-impact/D2018.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Economic Impact" />
      <Hero
        pageTitle="Economic Impact"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">Airport Development</span>
              <h2>
                Economic{" "}
                <span className="featured">
                  <span>Impact</span>
                </span>
              </h2>
              <p className="text-max-800">
                R.A. Wiedemann &amp; Associates provides clients with the latest
                in economic impact analyses techniques and studies. Using the
                international expertise of Dr. Geoffrey J.D. Hewings, this
                portion of the business examines the direct, indirect, and
                induced impacts associated with airport development and
                improvement.
              </p>
              <p>
                We uses the IMPLAN Input-Output Modeling System to measure
                specific and comparative economic impacts of airport development
                projects. IMPLAN not only quantifies the jobs, income, and
                output of various enterprises, it also assesses the tax impact
                for local, state, and federal revenues.
              </p>
              <blockquote>
                Because Dr. Hewings is a Special Sworn Agent of the U.S. Census
                Bureau, R.A. Wiedemann &amp; Associates has access to the U.S.
                Census Bureau’s establishment level data for businesses and
                manufacturing companies.
              </blockquote>
              <p>
                As a result, we can offer clients real world estimates of
                economic impact that can be defended/promoted in either positive
                or negative public forums.
              </p>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="statewide" className="section-4 offers">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="mt-0 mb-5 h2">
                <span className="featured">
                  <span>Statewide</span>
                </span>{" "}
                Economic Impact
              </h2>
            </div>
          </div>
          <div className="row justify-content-center items">
            <div className="col-12 col-md-6 item">
              <div className="card">
                <i className="icon fas text-primary fa-chart-pie fa-3x"></i>
                <Link to="/economic-impact/delaware">
                  <h4>Economic Impact Assessment of Delaware Airports</h4>
                </Link>
                <p>
                  Economic Impact studies performed by R.A Wiedemann &amp;
                  Associates in Delaware have been used to protect general
                  aviation airports and publicize the very large contribution of
                  jobs to the overall economy.
                </p>
                <p>
                  These studies have been received favorably by both the
                  government officials and the general public in Delaware.
                </p>
                <div className="buttons">
                  <div className="d-sm-inline-flex">
                    <Link
                      to="/economic-impact/delaware"
                      className="btn outline-button"
                    >
                      READ MORE
                    </Link>
                  </div>
                </div>
                <Link to="/economic-impact/delaware">
                  <i className="btn-icon pulse fas fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 item">
              <div className="card">
                <i className="icon fas text-primary fa-chart-pie fa-3x"></i>
                <Link to="/economic-impact/new-york">
                  <h4>New York Statewide Economic Impact Assessment</h4>
                </Link>
                <p>
                  Economic Impact studies for the state of New York involved the
                  estimation of IMPLAN impacts for 76 airports, including all 18
                  airline service facilities in the State.
                </p>
                <p>
                  The studies focused on targets that increased economic impacts
                  such as aviation manufacturing, air passenger enplanements,
                  air cargo, and business aviation.
                </p>
                <div className="buttons">
                  <div className="d-sm-inline-flex">
                    <Link
                      to="/economic-impact/new-york"
                      className="btn outline-button"
                    >
                      READ MORE
                    </Link>
                  </div>
                </div>
                <Link to="/economic-impact/new-york">
                  <i className="btn-icon pulse fas fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EconomicImpactPage
